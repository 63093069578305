import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb, IState} from "./Data";
import API from './../../common/AxoisClient';
import {showNotification} from "../../common";
import {AdminUserTabs} from "./Tabs";
import {AdminUserValidations} from "./Validations";
import {RoleModel} from "../../model";
import {AdminUserFormData} from "../../model";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton, Select,SwitchLoader} from "../../common";
import {LoaderType} from "../../common";

export class AdminUserEdit extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: AdminUserFormData(),
      id: props.match.params.id,
      roles: [],
    }
    AdminUserValidations();
  }

  componentDidMount() {
    API.get('users/admin-users/create').then((res) => {
      this.setState({
        roles: res.data
      })
    })

    API.get(`users/admin-users/${this.state.id}`).then((res: any) => {
      this.setState({
        form: AdminUserFormData(res.data)
      })
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#admin_user_form").valid()) {
      API.put(`users/admin-users/${this.state.id}`, this.state.form, {params: {from: 'info'}}).then((res:any) => {
        showNotification("success", res.data.message);
        this.props.history.push(`/users/admin-users`);
      });
    }
  }


  render() {
    return (
    
<CreateLayout breadcrumb={EditBreadcrumb} title={'edit_customer'}>
          <div kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper></div>
            <form className="kt-form" id="admin_user_form"  onSubmit={this.handleSubmit}>
            
            <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               <AdminUserTabs active={'info'} user_id={this.state.id}/>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
              <div className="kt-heading kt-heading--md">{Lang.edit_user}</div>
                   <div className="kt-portlet__body">
                    <div className="{'row'}">


                              <div className="form-group">
                                <label>{Lang.name} <span className={'required'}>*</span></label>
                                <Select onChange={this.handleChange} value={this.state.form.role_id} name={'role_id'} className="form-control" >
                                  <option value={''}>{Lang.select_role}</option>
                                  {this.state.roles.map((item:RoleModel, index: number) => {
                                       
                                    return <option value={item.id}>{item.name}</option>
                                  })}
                                </Select>
                              </div>

                              <div className="form-group">
                                <label> First Name <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.first_name} name={'first_name'} className="form-control" placeholder={"Enter first name"}/>
                              </div>

                              <div className="form-group">
                                <label>Last Name <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.last_name} name={'last_name'} className="form-control" placeholder={"Enter last name"}/>
                              </div>

                              <div className="form-group">
                                <label>{Lang.email} <span className={'required'}>*</span></label>
                                <input type={'email'} onChange={this.handleChange} value={this.state.form.email} name={'email'} className="form-control" placeholder={Lang.getString(`email_placeholder`)} />
                              </div>
                              <div className="form-group">
                                <label>Username <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange} value={this.state.form.username} name={'username'} className="form-control" placeholder={"Enter username"} />
                              </div>
                              

                              <div className="form-group ">
                                  <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                                  <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no} name={'mobile_no'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                              </div>
  
                        </div>
                        </div>
                        </div>
                </div>    
            <CommonSubmitButton/>
            </form>
            
        </CreateLayout>
   


       

        

    )
  }
}