import { Subject } from 'rxjs';
import { UserModel} from "../model";
import API from "../common/AxoisClient";
import {default as Lang} from "../common/lang/Lang";
import {showConfirmBox, showNotification, SwitchLoader} from "../common";
import {LoaderType} from "../common/Loader";

const subject = new Subject();


const userprofile = new Subject();
  export const UserProfileService = {
   userprofiledata: () =>userprofile.next(),
   getPopupState: () => userprofile.asObservable()
    };



const onlineTest = new Subject();

export const onlineTestService = {
  showPopup: (data: UserModel) => {
    getMailData(data.id).then(res => {
      if(res == 0){
        showNotification('danger', Lang.online_test_not_found);
      } else {
        onlineTest.next( {show: true, content: res, user: data} )
      }
    })
  },
  hidePopup: () => onlineTest.next({show: false,  user: {} }),
  getPopupState: () => onlineTest.asObservable()
};


const languagelist = new Subject();
  export const languagelistService = {
   languagedata: () =>languagelist.next(),
   getPopupState: () => languagelist.asObservable()
    };

const LanguageUpdate = new Subject();



const qualification = new Subject();

export const qualificationService = {
  showPopup: (data: UserModel) => qualification.next( {show: true, user: data} ),
  hidePopup: () => qualification.next({show: false,  user: {} }),
  getPopupState: () => qualification.asObservable()
};


const comments = new Subject();

export const commentsService = {
  showPopup: (data: UserModel) => comments.next( {show: true, user: data} ),
  hidePopup: () => comments.next({show: false,  user: {} }),
  getPopupState: () => comments.asObservable()
};





const leavesData = new Subject();

export const LeavesDataService = {
  refresh: (ref) => leavesData.next({refresh: ref}),
  setState: () => leavesData.asObservable()
};



/**
 *
 * @param payload
 * @returns {Promise<{translatorsList: any}>}
 */
export const getTranslators = async (payload)  => {
  const {order_id} = payload;
  const res = await API.get(`/orders/orders/for-assign/${order_id}`, {params: payload});
  return res.data;
}


/**
 *  assign translator for order
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const assignTranslators = async (payload)  => {
  const {order_id} = payload;
  const res = await API.post(`/orders/orders/for-assign/${order_id}`, payload);
  return res.data;
}

/**
 * login details on sms
 *
 * @param trans_id
 * @returns {Promise<T>}
 */
export async function smsLogin(trans_id) {
  showConfirmBox(Lang.sms_login_confirm, async () => {
    const res = await API.post(`/users/translator/sms-login/${trans_id}`);
    showNotification('success', res.data);
  })
}

export async function getMailData(trans_id) {
  const res = await API.get(`/users/translator/send-online-test/${trans_id}`);
  return res.data;
}

/**
 * sent online test to translator
 *
 * @param content
 * @returns {Promise<T>}
 */
export async function sendOnlineTestMail(content) {
  let formData = new FormData();
  Object.keys(content).map((item) => {
    formData.append(item, content[item]);
  });
  SwitchLoader(LoaderType.BUTTON);
  const res = await API.post(`/users/translator/send-online-test/${content.trans_id}`, formData);
  return res.data;
}

/**
 *  save qualifications
 *
 * @param user_id
 * @param form
 * @returns {Promise<T>}
 */
export async function saveQualificationData(user_id, form){
  SwitchLoader(LoaderType.BUTTON);
  const res = await API.post(`/users/translator/save-qualifications/${user_id}`, form);
  return res.data;
}

// get comments of translators
export async function getTranslatorComments(user_id) {
  const res = await API.get(`/users/translator/comments/${user_id}`,);
  return res.data;
}

// post comments
export async function postComments(user_id, form) {
  SwitchLoader(LoaderType.BUTTON);
  const res = await API.post(`/users/translator/comments/${user_id}`, form);
  return res.data;
}

// get translator language list
export async function getTranslatorLanguage(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/language-list/${user_id}`);
  return res.data;
}

// get translator language list
export async function getTranslatorLanguagelist(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/translator-language-list/${user_id}`);
  return res.data;
}

// get translator language list
export async function FromTranslatorLanguage(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/from-translator-language/${user_id}`);
  return res.data;
}

export async function ToTranslatorLanguage(user_id) {
  SwitchLoader(LoaderType.NONE);
  const res = await API.get(`/users/translator/to-translator-language/${user_id}`);
  return res.data;
}

// get leaves list of translator
export async function getTranslatorLeaves(user_id) {
  const res = await API.get(`/users/translator/leaves-list/${user_id}`);
  return res.data;
}

// get leaves list of translator
export async function languageActions(data) {
  const res = await API.post(`/users/translator/language-actions`, data);
  return res.data;
}


export async function getOnlineTestAnswers($trans_id){
  const res = await API.get(`/users/translator/online-test-answers/${$trans_id}`);
  return res.data;
}

export async function getOnlineQuizzes($trans_id){
  const res = await API.get(`/users/translator/online-quiz-answers/${$trans_id}`);
  return res.data;
}


export async function createLeave($trans_id, data){
  const res = await API.post(`/users/translator/leaves/${$trans_id}`, data);
  return res.data;
}


export async function deleteLeave(leave_id){
  showConfirmBox(Lang.VT_ADMIN_3, async () => {
    const res = await API.post(`/users/translator/leaves/${leave_id}`, {"_method": "delete"} );
    LeavesDataService.refresh(true);
    showNotification('success', res.data);
  })
}
