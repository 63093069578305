import * as React from 'react';
import {EditBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {PillarForm} from "./form/PillarForm"
import {PillarModel} from "../../model";
import {PillarService} from "../../services/PillarService";

export class PillarUpdate extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      pillar: {} as PillarModel
    }
  }

  init = async () => {
    const pillarData: PillarModel = await PillarService.getPillarInfo(this.state.id)
    this.setState({
      pillar: pillarData
    })
  }

  componentDidMount() {
    this.init();
  }


  render() {
    return (
        <CreateLayout breadcrumb={EditBreadcrumb} title={'Edit'}>
          <PillarForm {...this.state}/>
        </CreateLayout>
    )
  }
}