import API from "../common/AxoisClient";

export class QuestionnaireService {

  /**
   * get pillers list
   */
  static async getAgeGroups(){
    const res = await API.get(`quiz/questionnaire/get-age-groups`);
    return res.data
  }

  static async storeQuestionnaire(data) {
    const res = await API.post(`quiz/questionnaire`, data);
    return res.data
  }

  static async updateQuestionnaire(data, id){
    const res = await API.post(`quiz/questionnaire/${id}`, {...data, "_method": "put"});
    return res.data
  }

  static async getQuestionnaireInfo(id: number){
    const res = await API.get(`quiz/questionnaire/${id}`);
    return res.data
  }


}