import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {ClubModel, UserModel} from "../../../model";
import {IState} from "../Data";
import {ClubService} from "../../../services";

export const ClubForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as ClubModel);
  const [coachDirectors, setCoachDirectors] = React.useState([] as Array<UserModel>);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0]  : target.value;
    const name = target.name;
    setForm({...form, [name]: value})
  }

  React.useEffect(() => {
    setForm(props.club)
  },[props.club])

  React.useEffect(() => {
    async function init() {
      const coachesList: Array<UserModel> = await ClubService.coachDirectorsList();
      setCoachDirectors(coachesList);
    }
    init()
  },[])



  const FormInputs = [
    {
      "name" : "name",
      "value" : _.get(form, "name", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter club name",
      "label": "Club name",
    },
    {
      "name" : "user_id",
      "value" : _.get(form, "user_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Coach Director",
      "label": "Club Coach Director",
      "options" : coachDirectors
    },
    {
      "name" : "file",
      //"value" : _.get(form, "icon", ""),
      "onChange": handleChange,
      "type": "file",
      "label": "Logo",
      "file_path":  _.get(form, "logo", "")
    },
  ];

  function isDisable() {
    return FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id){
      ClubService.updateClub(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/clubs`)
      }).catch(() => {});
    } else {
      ClubService.storeClub(form).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/clubs`)
      }).catch(() => {});
    }
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
      )
}