import * as React from 'react';
import {IState as CommonState} from "../../common/State";
import {actionButton, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb, TeamFormData} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {listingStatus} from "../../helpers/UserHelper";
import {AgeGroupModel, ClubModel, TeamModel, UserModel} from "../../model";
import {ClubService} from "../../services";

interface IState extends CommonState {
  clubs: Array<ClubModel>;
  coaches: Array<UserModel>;
  ageGroups: Array<AgeGroupModel>;

}

export class Teams extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  componentDidMount() {
    const ___that = this;
    async function  init() {
      const dataForTeam: any = await ClubService.dataForTeam();
      ___that.setState({
        clubs: [{"label": "Select Club", "value": ""}, ...dataForTeam.clubs],
        coaches: [{"label": "Select Age Coach", "value": ""}, ...dataForTeam.coaches],
        ageGroups: [{"label": "Select Age Group", "value": ""}, ...dataForTeam.age_groups],
      })
    }
    init();
  }

  showContent = (data: TeamModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("name", data.name),
      this.records.current.createdTd("club_id", data.club.name),
      this.records.current.createdTd("user_id", data.coach.name),
      this.records.current.createdTd("age_group_id", data.age_group.title),
      this.records.current.createdTd("team_members_count", data.team_members_count),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at",data.created_at),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: TeamModel) => {
    return (
      <EditLink scope={'teams.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Teams"} icon={Icons.club} >
            <ExportButton records={this.records} fileName={"Teams"}/>
            &nbsp;
            <CreateLink to={`/clubs/teams/create`} scope={'teams.store'} />
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords}  {...this.state}
            />}
            table={(
              <ShowRecords
                url={"/clubs/teams"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "clubs/teams/bulk-action", "module": 'teams'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
