import React from "react";
import _ from "lodash";
import {CommonSubmitButton, isEmpty, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import { TeamModel} from "../../../model";
import {IState, TeamFormData} from "../Data";
import {ClubService} from "../../../services";


export const TeamForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as TeamModel);
  const [data, setData] = React.useState({} as TeamFormData);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    setForm({...form, [name]:  value})
  }

  const handlePlayers = (inputValue) => {
    setForm({...form, team_members_field:  inputValue})
  }

  React.useEffect(() => {
    const {team} = props;
    if(team){
      team["team_members_field"] =  defaultTeamMembers(team.team_members) ;
      setForm(team)
    }
  },[props.team])

  React.useEffect(() => {
    async function init() {
      const dataForTeam:TeamFormData = await ClubService.dataForTeam();
      setData(dataForTeam)
    }
    init()
  },[])

  const loadPlayer = async (inputValue, callback) => {
    const playersData = await ClubService.playersList(inputValue);
    callback(playersData);
  }

  function defaultTeamMembers(team_members) {
    let teamMembers = [];
    const oldMembers = team_members;
    if(oldMembers){
      // @ts-ignore
      oldMembers.map(item => teamMembers.push({value: item.user_id,user_id: item.user_id, label: item.user.name}))
    }
    return teamMembers;
  }



  const FormInputs = [
    {
      "name" : "name",
      "value" : _.get(form, "name", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter team name",
      "label": "Team name",
    },
    {
      "name" : "club_id",
      "value" : _.get(form, "club_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Club",
      "label": "Club",
      "options" : _.get(data, "clubs", [])
    },
    {
      "name" : "user_id",
      "value" : _.get(form, "user_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Coach",
      "label": "Team Coach",
      "options" : _.get(data, "coaches", [])
    },
    {
      "name" : "age_group_id",
      "value" : _.get(form, "age_group_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Age Group",
      "label": "Age Group",
      "options" : _.get(data, "age_groups", [])
    },
    {
      "name" : "team_members_field",
      "value" :  _.get(form, "team_members_field", []),
      "onChange": handlePlayers,
      "required": true,
      "isMulti": true,
      "type": "select2",
      "placeholder": "Select Players",
      "label": "Players",
      "loadOptions" : loadPlayer
    },

  ];

  function isDisable() {
    return FormInputs.reduce((acc, val) => acc ? acc : isEmpty(val.value) && val.required, false);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id){
      ClubService.updateTeam(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/teams`)
      }).catch(() => {});
    } else {
      ClubService.storeTeam(form).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/teams`)
      }).catch(() => {});
    }
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
      )
}