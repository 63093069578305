import React from 'react';
import _ from 'lodash';
import {default as Lang} from "../common/lang/Lang";



export function state_to_form_data(state: any) {
  let formData = new FormData();
  _.forEach(state, (value, key) => {
    if(_.isObject(value) || _.isArray(value)){
      formData.append(key, JSON.stringify(value));
    }else {
      formData.append(key, value);
    }
  });
  return formData;
}

export function listingStatus(status) {
  if(status === 1){
    return <span className={'badge badge-primary'}>{Lang.active}</span>
  }
  if(status === 2){
    return <span className={'badge badge-warning'}>{Lang.inactive}</span>
  }
}