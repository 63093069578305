import API from "../common/AxoisClient";
import {LoaderType} from "../common/Loader";
import {showConfirmBox, showNotification,SwitchLoader} from "../common";
import {default as Lang} from "../common/lang/Lang";


/**
 * check special company
 * @param email
 * @returns {Promise<T>}
 */
export async function isSpecialCompany(email) {
  SwitchLoader(LoaderType.INLINE);
  const res  = await  API.get(`settings/company/check-special-company-email/${email}`);
  return res.data;
}

export async function getCustomerInfo(id) {
  const res  = await API.get(`users/customers/${id}`);
  return res.data;
}

export async function sendMail(id) {
  await showConfirmBox("Are you sure that you want to send email again?", async () => {
    const res = await API.post(`/users/users/resend-email/${id}`);
    showNotification('success', res.data);
  })
}
