import * as React from 'react';
import { CreateBreadcrumb, IState} from "./Data";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import { UserForm} from "./form/UserForm";

export class CustomerCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
  constructor(props:any){
    super(props);
    this.state = {} as IState;
  }


  componentDidMount() {
    API.get("users/users/get-roles").then(res => {
      this.setState({
        roles: res.data
      })
    })
  }



  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'Add New User'}>
          <UserForm roles={this.state.roles} update={false} />
        </CreateLayout>
   )
  }
}