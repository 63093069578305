import * as React from 'react';
import _ from "lodash";
import {Select} from "./Select";
import {DatePickerWrapper} from "./DatePicker";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import {CONST} from "../Common";
import {Select2Wrapper} from "../Select2";
import AsyncSelect from 'react-select/async';


export const FormElementGroup = (props: any) => {
  return (
      <div className={"col-md-6 col-lg-6"}>
          <div className="form-group">
              { props.children }
          </div>
      </div>
  )
}

export function renderOptions(options) {
  return options.map((item, index) => {
    return <option value={item.id} key={index}>{ item.name }</option>
  })
}

const InputFields = (field) => {

    const {type} = field;
    switch (type) {
        case "select":
            const {options, ...rest}  = field;
            return <Select className="form-control" {...rest}>
                { !_.isEmpty(rest.placeholder) && <option value={""}>{ rest.placeholder}</option> }
                { renderOptions (options) }
            </Select>

      case "select2":
        return <AsyncSelect cacheOptions {...field}/>

        case "date":
          return <DatePickerWrapper className="form-control" {...field}/>

        case "file":
          return <div className="custom-file">
                  <input className="custom-file-input" {...field} />
                  <label className="custom-file-label" htmlFor="customFile"/>
                </div>

        case "editor":
            return <Editor apiKey={CONST.editorKey} id='message'
                           init={CONST.initialEditorOptions} {...field} />

        default:
            return <input className="form-control" {...field}/>
    }
}


export const RenderForm = ({items}) => {
    return items.map((field, index) => {
      const {label, required, parent_class, show, ...rest} = field;
      if(show !== undefined && show === false){
        return null;
      }
      return (
        <div className={parent_class ? parent_class : "col-md-6 col-lg-6"} key={index}>
          <div className="form-group">
            <label>{label}
                { required && <span className={'required'}>*</span>}
            </label>
            <InputFields {...rest}/>
            {
              (field.type === "file" &&  !_.isEmpty(field.file_path)) && <div style={{marginTop:10}}>
                <img src={field.file_path} className={"img-responsive"} width={100} height={100}/>
              </div>
            }
          </div>
        </div>
      )
    })
}