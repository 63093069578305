import * as React from 'react';
import { ClubModel} from "../../model";
import {IState as CommonState} from "../../common/State";
import {actionButton, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {listingStatus} from "../../helpers/UserHelper";

interface IState extends CommonState {


}

export class Clubs extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();
  

  showContent = (data: ClubModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("club_code", data.club_code),
      this.records.current.createdTd("name", data.name),
      this.records.current.createdTd("user_id", data.coach_director.name),
      this.records.current.createdTd("logo", <img src={data.logo} className={"img-responsive"} height={100} width={100}/>),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at",data.created_at),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: ClubModel) => {
    return (
      <EditLink scope={'clubs.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Clubs"} icon={Icons.club} >
            <ExportButton records={this.records} fileName={"Clubs"}/>
            &nbsp;
            <CreateLink to={`/clubs/clubs/create`} scope={'clubs.store'} />
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords}
            />}
            table={(
              <ShowRecords
                url={"/clubs/clubs"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "clubs/clubs/bulk-action", "module": 'clubs'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
