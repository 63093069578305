import * as React from 'react';
import {UserDataSchema} from "./Model";
import {IState as CommonState} from "../../common/State";
import {default as Lang} from "../../common/lang/Lang"
import {CONST, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {UserModel} from "../../model";
import {ListBreadcrumb} from "./Data";
import { CreateLink, EditLink, ShowRecords, Icons, actionButton} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import AdminFilter from './filter/AdminFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";

interface IState extends CommonState{
  UserData: Array<UserDataSchema>;
   
}


export class AdminUsers extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();
 
  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  showContent = (data: UserModel, index: number) => {
   
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
        this.records.current.createdTd("name", ()=>{
            return (
                <div style={{width:'150px'}}>{data.name}</div>
            )
        }),
        this.records.current.createdTd("role", ()=>{
            return (
                <div style={{width:'150px'}}>{data.role.name}</div>
            )
        }),
      this.records.current.createdTd("email", data.email),
      this.records.current.createdTd("mobile_no", () => {
          if(!_.isEmpty(data.mobile_no)){
              return <div style={{width:'120px'}}>{ data.mobile_no}</div>
          }
          return null;
      }),
      this.records.current.createdTd("status", () => {
        if(data.status === 1){
          return <span className={'badge badge-primary'}>{Lang.active}</span>
        }
        if(data.status === 2){
          return <span className={'badge badge-warning'}>{Lang.inactive}</span>
        }
      }),
      this.records.current.createdTd("created_at", ()=>{
        return (
            <div style={{width:'150px'}}>{data.created_at}</div>
        )
      }),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);

    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


 


  rowActionButtons = (data: UserModel) => {
    return (
      <EditLink scope={'admin-users.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'admin_users'} icon={Icons.users} >
            <ExportButton records={this.records} fileName={Lang.exportAdminUsers}/>
            &nbsp;
            <CreateLink to={`${this.props.match.url}/create`} scope={'admin-users.store'} />
          </ListHeader>

          <ListForm
            form={(
              <AdminFilter status={status}   getResults={this.filterRecords} />
            )}
            table={(
              <ShowRecords
                url={"/users/admin-users"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "users/admin-users/bulk-action"})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
