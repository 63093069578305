import * as React from 'react';
import { getQueryStringParams, SearchInput} from "../../../common";
const moment = require('moment');

interface IState {
  form: {
    like: {
       subject: string,
    },
    equal: {
      status: number
    }
  }
 }

export default class EmailFilter extends React.Component<any, IState> {
  state: IState;


  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          subject: old_data ? old_data.like.subject : '',
          
        },
        equal: {
          status: old_data ? old_data.equal.status : ''
        }
      }})
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

   

  render(){
    const {status} = this.props;
    return (
      <div className="row align-items-center">
      <SearchInput name={'form.like.subject'} value={this.state.form.like.subject} onChange={this.handleInputForm} placeholder={'Enter subject'} />
    </div>
    )
  }
}