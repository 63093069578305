import React, {useState, useEffect, useReducer} from 'react';
import Lang from "../../../common/lang/Lang";
import {Modal} from "react-bootstrap";
import {Icons, SubmitButton} from "../../../common";
import {QuestionListService, QuestionPopupService, QuestionService} from "../../../services";
import {CAction, InitialState, PopupState, QuestionReducer, ActionType, QuestionsValidations} from "./QuestionReducer";
import {QuestionType, QuestionTypeTitle, QuizQuestionOptionModel} from "../../../model";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";

export function QuestionPopup(props: any){

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(QuestionReducer , InitialState);
  const [con, setCon] = useState('');

  useEffect(() => {
    QuestionPopupService.handleEvent().subscribe((message: any) => {
      if(message.action === ActionType.EDIT_QUESTION_POPUP){
        setCon('1');
      }
      dispatch({type: message.action, payload: message}) ;
    })
  });

  if(state.show === false){
    return null;
  }

  function handleChange(e) {
    const target : any = e.target;
    const value = target.value;
    const name = target.name;
    dispatch({type: ActionType.HANDLE_CHANGE, payload: { [name]: value } as any});
  }

  function handleEditorChange(value) {
    setCon( value !== '' ? '1' : '');
    dispatch({type: ActionType.HANDLE_CHANGE, payload: { question: value } as any});
  }
  function handleOptionChange(e) {
    dispatch({type: ActionType.HANDLE_OPTION_CHANGE, payload: e.target});
  }

  function handleSubmit(e) {
    e.preventDefault();
    if($("#question_form").valid()){
      QuestionService.store(state.quiz_id, state.form).then(res => {
        if(state.question_id > 0){
          QuestionListService.edit(res);
        } else{
          QuestionListService.add(res);
        }
        QuestionPopupService.hide();
      });
    }
  }
  QuestionsValidations();

  const {form} = state;
  return (
    <Modal
      show={state.show}
      onHide={() => {
        dispatch({type: ActionType.HIDE_POPUP});
      } }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >

      <form onSubmit={handleSubmit} id={'question_form'}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {state.question_id > 0 ? Lang.edit_question : Lang.add_question}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div className={'row'}>
              <div className={'col-md-12'} >
                <div className="form-group">
                  <label className={'col-2 col-form-label'}> {Lang.question_type}<span className={'required'}>*</span></label>
                  <div className="col-10">
                    <div className="kt-radio-inline">
                      <label className="kt-radio kt-radio--bold kt-radio--brand" style={{width: '30%'}}>
                        <input type={'radio'} onChange={handleChange} value={QuestionType.OBJECTIVE} name={'question_type'} checked={form.question_type == QuestionType.OBJECTIVE} className="form-control" />
                        {QuestionTypeTitle[QuestionType.OBJECTIVE]}
                        <span/>
                      </label>
                      <label className="kt-radio kt-radio--bold kt-radio--brand" style={{width: '30%'}}>
                        <input type={'radio'} onChange={handleChange} value={QuestionType.MULTI_CHOICE} name={'question_type'} checked={form.question_type == QuestionType.MULTI_CHOICE} className="form-control" />
                        {QuestionTypeTitle[QuestionType.MULTI_CHOICE]}
                        <span/>
                      </label>
                      <label className="kt-radio kt-radio--bold kt-radio--brand" style={{width: '30%'}}>
                        <input type={'radio'} onChange={handleChange} value={QuestionType.SUBJECTIVE} name={'question_type'} checked={form.question_type == QuestionType.SUBJECTIVE} className="form-control" />
                        {QuestionTypeTitle[QuestionType.SUBJECTIVE]}
                        <span/>
                      </label>
                    </div>
                  </div>
                </div>

                <div className={'form-group'}>
                  <input type={'hidden'} value={`${con}`} id={'is_ques_con'} onChange={() => {}} name={'is_ques_con'}/>
                  <label>{Lang.question}<span className={'required'}>*</span></label>
                  <Editor id='content'
                          init={{
                            menubar:false,mode: "textareas", branding: false, forced_root_block : false
                          }} value={form.question} onEditorChange={handleEditorChange} />
                  <div id="content-error"/>
                </div>

                <div className={'form-group'}>
                  <label>{Lang.marks}<span className={'required'}>*</span></label>
                  <input type={'text'} name={'total_marks'}
                         value={form.total_marks|| ''} onChange={handleChange}
                         className={'form-control'} required={true}/>
                </div>

                {
                  form.question_type != QuestionType.SUBJECTIVE &&
                  <div>
                    {
                      form.options.map((item:QuizQuestionOptionModel, index: number) => {
                        return (
                          <div className={'form-group'} key={index} >
                            <label>{Lang.option} {index+1}<span className={'required'}>*</span></label>
                            <div className={'row'}>
                              <div className={'col-md-7'}>
                                <input type={'text'} name={'options'}  data-id={index}
                                       value={item.options || ''} onChange={handleOptionChange}
                                       className={'form-control option-group'} />
                              </div>
                              <div className={'col-md-3'}>
                                {
                                  form.question_type == QuestionType.MULTI_CHOICE &&
                                  <label className="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                                    <input type={'checkbox'}  data-id={index}  onChange={handleOptionChange} checked={(item.is_correct === true || item.is_correct === 1)} name={'is_correct'} className="form-control" />
                                    {Lang.is_correct}?
                                    <span/>
                                  </label>
                                }
                                {
                                  form.question_type == QuestionType.OBJECTIVE &&
                                  <label className="kt-radio kt-radio--bold kt-radio--brand">
                                    <input type={'radio'}  data-id={index}  onChange={handleOptionChange} checked={(item.is_correct === true || item.is_correct == 1)} value={1} name={'is_correct'} className="form-control" />
                                    {Lang.is_correct}?
                                    <span/>
                                  </label>
                                }
                                <div className={'is_correct_error'}/>
                              </div>
                              {
                                index > 1 &&
                                <div className={'col-md-2'}>
                                  <button type={'button'} className={'btn btn-danger btn-icon'} title={'Remove options'} onClick={() => {dispatch({type: ActionType.REMOVE_OPTIONS, payload: index} as any)}}><i className={Icons.delete}/></button>
                                </div>
                              }

                            </div>
                          </div>
                        )
                      })
                    }
                    <div className={'col-md-12 text-right'}>
                      <button type={'button'} className={'btn btn-primary btn-icon'} title={'Add more options'} onClick={() => {dispatch({type: ActionType.ADD_OPTIONS})}}><i className={Icons.add}/></button>
                    </div>
                  </div>
                }


              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <SubmitButton className={'btn btn-success'} label={Lang.submit} />
          <button className={'btn btn-primary'} onClick={() => {dispatch({type: ActionType.HIDE_POPUP });} }>{Lang.close}</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}