import React, {useEffect, useRef} from 'react';


interface SelectProps  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options?: Array<any>
}


export function Select(props: SelectProps) {

  const ele = useRef(null);

  useEffect(() => {
    let $el = ele.current;
    if($el !== null){
      let $els = $($el);
      ($els as any).selectpicker('refresh')
    }

  });

  return(
    <>
      <select data-live-search="true" {...props} ref={ele}>
        {props.children}
      </select>
    </>
  )
}