import * as React from 'react';
import { getQueryStringParams,SearchSelect, SearchInput} from "../../../common";

interface IState {
  form: {
    like: {
      search: string,
      email: string
    },
    equal: {
      status: number,
      role_id: number;
      age_group_id: number;
    }
  },
 }

export default class CustomerFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          search: old_data ? old_data.like.search : '',
          email: old_data ? old_data.like.email : ''
        },
        equal: {
          status: old_data ? old_data.equal.status : '',
          role_id: old_data ? old_data.equal.role_id : '',
          age_group_id: old_data ? old_data.equal.age_group_id : '',
        }
      }})
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }
 

  render(){
    const {status, roles, age_groups} = this.props;
    return (
      <div className="row align-items-center">
       <SearchInput name={'form.like.search'} value={this.state.form.like.search} onChange={this.handleInputForm} placeholder={'Search User..'} />
        <SearchSelect name={'form.equal.role_id'} value={this.state.form.equal.role_id}  onChange={this.handleInputForm} options={roles}/>
        <SearchSelect name={'form.equal.age_group_id'} value={this.state.form.equal.age_group_id}  onChange={this.handleInputForm} options={age_groups}/>
        <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={status}/>
      </div>
    )
  }
}