import {ConceptModel} from "../../model";

export const urls = {
  home: "/pillars/",
  list: "/pillars/concepts",
}

export const ListBreadcrumb = [
  {
    label: "Pillars",
    url: urls.home
  },
  {
    label: "Concepts",
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Pillars",
    url: urls.home
  },
  {
    label: "Concepts",
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Pillars",
    url: urls.home
  },
  {
    label: "Concepts",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export interface IState {
  id: number,
  concept: ConceptModel
}