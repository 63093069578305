import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Auth } from "../Auth/Auth";
import { HandleServerError } from "./HandleError";
import { LoaderType, ShowLoader, StopLoader } from "./Loader";
import { RequestService } from "../services/RequestService";

export const base_url = "https://pillar-api.iamabhishek.com/";

let loader = LoaderType.COMMON;
let waitingFrom = 'none';

export function SwitchLoader(state: LoaderType) {
  loader = state;
}

export function SetWaitingArea(state: string) {
  waitingFrom = state;
}

const Axios = axios.create({
  baseURL: `${base_url}api/admin/`,
});


// Add a request interceptor
Axios.interceptors.request.use((config: AxiosRequestConfig) => {
  ShowLoader(loader);
  config.headers['Authorization'] = `Bearer ${Auth.token}`;
  if (loader !== LoaderType.BUTTON) {
    RequestService.start(waitingFrom);
  }
  return config;
}, (error: any) => {
  return Promise.reject(error);
});



// Add a response interceptor
Axios.interceptors.response.use((response: AxiosResponse) => {
  RequestService.end();
  StopLoader(loader);
  waitingFrom = 'none';
  return response;

}, (error: AxiosError) => {
  waitingFrom = 'none';
  RequestService.end();
  StopLoader(loader);
  HandleServerError(error);

  return Promise.reject(error.response);

});

export default Axios;