import * as React from 'react';
import Lang from "../../common/lang/Lang";
import API from "../../common/AxoisClient";
import {showNotification} from "../Common";
import {SubmitButton,SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
declare let window: any;

interface ChangePasswordProps{
  id: number
}

interface IState {
  password: string;
  cn_password: string
}

export class ChangePassword extends React.Component <ChangePasswordProps>{
  state: IState;
  constructor(props: any){
    super(props)
    this.state = {
      password:'',
      cn_password: ''
    }
    PasswordValidations()
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#password_form").valid()){
      API.post(`users/customers/change-password/${this.props.id}`, this.state).then((res: any) => {
        if(res.data){
          this.setState({
            password:'',
            cn_password: ''
          });
          showNotification("success", "Password has been successfully changed.");
        }
      })
    }
  }

  render() {
    return(
        <form className="kt-form" id={'password_form'} onSubmit={this.handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <div className={"col-md-6 col-lg-12"}>
                <div className="form-group">
                  <label>{Lang.password} <span className={'required'}>*</span></label>
                  <input type={'password'} required={true} onChange={this.handleChange} value={this.state.password} id={'password'} name={'password'} className="form-control" placeholder={Lang.getString(`password_placeholder`)}/>
                </div>
              </div>

              <div className={"col-md-6 col-lg-12"}>
                <div className="form-group">
                  <label>{Lang.cn_password} <span className={'required'}>*</span></label>
                  <input type={'password'} required={true} onChange={this.handleChange} value={this.state.cn_password} name={'cn_password'} className="form-control" placeholder={Lang.getString(`cn_password_placeholder`)}/>
                </div>
              </div>

            </div>
          </div>
          <div className="kt-portlet__foot">
            <div className="kt-form__actions">
              <div className="row">
                <div className="col-lg-6">
                </div>
                <div className="col-lg-6 kt-align-right">
                <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                </div>
              </div>
            </div>
          </div>
        </form>
    )
  }
}

const PasswordValidations = function () {

  $(document).ready(function () {
    $( "#password_form" ).validate({
      rules: {
        password: "required",
        cn_password: {
          equalTo: "#password"
        },
      }
    });
  });
}