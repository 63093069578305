import * as React from 'react';
import {BulkActionButtonSchema, Button} from "../form/Button";
import {IState, RecordsSchema} from "../State";
import {TableHeaderSchema} from "./Model";
import Lang from "../lang/Lang";

interface TableActionsSchema extends RecordsSchema, IState {
  onFieldUpdate: (item: TableHeaderSchema) => void;
  limits? :any
}

export class TableActions extends React.Component<TableActionsSchema> {



  render() {
    const style = {
      filedButton: {
        "display": "flex",
        "flex-direction": "row",
        "justify-content": "flex-end",
      }
    }
   return(
      <div className="kt-portlet__body">
        <div className="kt-form kt-form--label-align-right kt-margin-t-10  kt-margin-b-10 collapse show" id="kt_datatable_group_action_form" >
          <div className="row">
            <div className="col-xl-6">
            {
              this.props.isNeedAction === true && this.props.selected && this.props.selected.length > 0 &&

                  <div className="kt-form__group kt-form__group--inline">
                    <div className="kt-form__label kt-form__label-no-wrap">
                      <label className="kt-font-bold kt-font-danger-">Selected
                        <span id="kt_datatable_selected_number"> {this.props.selected.length} </span> records:</label>
                    </div>
                    <div className="kt-form__control">
                      <div className="btn-toolbar">
                        {
                          this.props.actionButtons.map((button: BulkActionButtonSchema, index: number) => {
                            if(button.show){
                              return (
                                <span  key={index} >
                                &nbsp;&nbsp;&nbsp;
                                  <Button type={button.type} label={button.label} class={button.class} onClick={button.onClick}/>
                              </span>
                              );
                            }
                          })
                        }
                      </div>
                    </div>
                  </div>
            }
            </div>
            <div className={"col-xl-3"} style={style.filedButton}>
              <div className="kt-form__group kt-form__group--inline">
                <div className="kt-form__control">
                  <div className="btn-toolbar text-right">
                    <div className="dropdown dropdown-inline">
                      <button type="button" className="btn btn-brand btn-icon-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="flaticon2-indent-dots"/>{Lang.Fields}
                      </button>
                      <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{position: 'absolute', top: "0px", left: "0px", transform: "translate3d(110px, 38px, 0px)"}}>
                        <ul className="kt-nav">
                          <li className="kt-nav__section kt-nav__section--first">
                            <span className="kt-nav__section-text">{Lang.choose_fields}:</span>
                          </li>
                          {this.props.cols && this.props.cols.map((item: TableHeaderSchema, index: number) => {
                              return(
                                <li className="kt-nav__item" key={index}>
                                  <label className="kt-nav__link">
                                    <input type={'checkbox'} checked={item.show} onChange={() => this.props.onFieldUpdate(item)}/> &nbsp; &nbsp; &nbsp;
                                    <span className="kt-nav__link-text">{item.label}</span>
                                  </label>
                                </li>
                              )
                          })}

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-3"} style={style.filedButton}>
              {this.props.limits}
            </div>
          </div>
        </div>
      </div>
    );
  }


}
