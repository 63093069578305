import * as React from 'react';
import {Table} from "./table/Index";
import {Pagination} from "./Pagination";
import {IState, RecordsSchema} from "./State";
import API, {SwitchLoader} from "./AxoisClient";
import {getOldFormData, updatePageUrl} from "./Common";
import {TableActions} from "./table/TableActions";
import {TableHeaderSchema} from "./table/Model";
import axios from 'axios'
import Lang from "./lang/Lang";
import _ from 'lodash';
import {LoaderType} from "./Loader";
import {SelectOptions} from "./form/SearchInput";
import {Limit} from "./table/Limit";
import moment from "moment";

let fileDownload = require('js-file-download');

declare var window: any;
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let cancel : any;
let TdCols : Array<string> = [];

export class ShowRecords extends React.PureComponent <RecordsSchema, IState>{
  state: IState;

  async componentDidMount() {
    TdCols = [];
    let old_data:any = getOldFormData();
    if(old_data){
      // set old search params into state
      await this.setState({
        first: true,
        search: old_data,
      }, this.fetchData);
    } else {
      await this.fetchData();
    }
  }

  /**
   * @method
   * @desc set search form values into state and filter results
   * @param values
   * @param refresh
   */
  setFormFields = (values? : any, refresh?: boolean) => {
    source.cancel('Operation canceled by the user.')
    let state_data: any = Object.assign({}, this.state ? this.state.search : {});
    if(values !== undefined){
      state_data.form = values;
    }
    if(!refresh){
      state_data.page = 1;
    }
    this.fetchData(state_data);
    /*this.setState({
      search: state_data
    }, this.fetchData);*/
  }


  // get records from API
  fetchData = (data? :any) => {
    cancel && cancel();
    const __this = this;
    this.setState({is_load: true});
    const search_data = data !== undefined ? data : (this.state ? this.state.search : {});
    SwitchLoader(LoaderType.NONE);
    API.get(this.props.url, {  cancelToken: new CancelToken( (c) => {
        cancel = c;
      }), params: search_data}
      ).then((res : any) => {
      this.setState({is_load: false});
        let url_params = res.request.responseURL.split("?");
        if(url_params[1] !== undefined)
          updatePageUrl(`?${url_params[1]}`);
        this.recordsSetSate(res.data, search_data);
    })
      .catch(function (error) {
        __this.setState({
          records: []
        })
      })
  };

  recordsSetSate(res: any, search_data: any) {
    this.setState({
      first: false,
      search: search_data,
      records: res.data,
      PaginationMeta:res.meta,
      cols:res.cols,
      selected: []
    }, () => {
      if(this.props.callBack !== undefined)
        this.props.callBack(res);
    })
  }

  // pagination
  changePage =  (selectedItem: { selected: number }) => {
    let state_data: any = Object.assign({}, this.state ? this.state.search : {});
    state_data.page = selectedItem.selected + 1;
    this.fetchData(state_data);
   // this.setState({search: {...this.state.search, page: selectedItem.selected + 1} }, this.fetchData );
  };

  // change no of list of records on single page
  changeLimit = (selected: number ) => {
    let state_data: any = Object.assign({}, this.state ? this.state.search : {});
    state_data.limit = selected;
    this.fetchData(state_data);
    //this.setState({search: {...this.state.search, limit: selected } } , this.fetchData );
  };

  //sorting
  sort = (column: string, type: string ) => {
    let state_data: any = Object.assign({}, this.state ? this.state.search : {});
    state_data.sort = column;
    state_data.type = type;
    this.fetchData(state_data);
    //this.setState({search: {...this.state.search, sort: column, type: type } } , this.fetchData );
  };

  // create checkbox for select multiple records
  checkbox = (id: number) => {
      return (
          <label className="kt-checkbox kt-checkbox--single kt-checkbox--solid vishant">
            <input type="checkbox"
                   checked={ this.state.selected.indexOf(id) > -1 }
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleCheckboxes(e, id)}
            />
            &nbsp;<span/>
          </label>
      );
  }

  async handleCheckboxes(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    if(event.target.checked === true){
      await this.setState(prevState => ({ selected: [...prevState.selected, id] }));
    } else {
      await this.setState({selected: this.state.selected.filter(function(item: number) {
          return item !== id
        })});
    }
  }

  // check all records
   checkedAll = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked === true){
      await this.setState({selected: this.state.records.map(function(item) {
          return item.id
        })});
    } else {
      await this.setState({selected: []});
    }
  };

  // export results
  export = (type: string, fileName:string) => {
    $('.exportButtonBeforeClick').addClass('hide-me');
    $('.exportButtonAfterClick').removeClass('hide-me');
    API.get(`${this.props.url}`, {responseType: 'blob' ,params: {...this.state.search, export: type}}).then((res) => {
      const  file_type = type === 'excel' ? 'xlsx' : type;
      $('.exportButtonBeforeClick').removeClass('hide-me');
      $('.exportButtonAfterClick').addClass('hide-me');
      let fileGoodName = `${fileName}_${moment().format("DD-MMMM-YYYY")}.${file_type}`;
      fileDownload(res.data, fileGoodName);
    })
  };


  // add or remove fields to show in table
  addRemoveFields = (item: TableHeaderSchema) => {
    TdCols = [];
    this.setState({cols: this.state.cols.filter(function(state_item: TableHeaderSchema) {
        if(state_item.name === item.name){
          state_item.show = !item.show;
        }
        return state_item;
      })});
  };

  /**
   * @method createdTd
   * create TD which need to show by backend
   * @param {string} col
   * @param value
   * @returns {any}
   */
  createdTd = (col: string, value: any) => {
    if(TdCols.length <= 0){
      // get only show colums set by backend
      this.state.cols.map((item: TableHeaderSchema, index) => {
        if(item.show === true){
          return TdCols.push(item.name)
        }
      })
    }
    // return column value
    if(TdCols.indexOf(col) >= 0){
      const key = _.random(1, 9999);
      if(typeof value === "function"){
        return <td key={key}>{value()}</td>
      } else {
        return <td key={key}>{value}</td>
      }
    }
  }

  /**
   * @Method Records
   * @desc create default table rows for data from API
   * @param data
   * @returns {any[]}
   * @constructor
   */
  Records = (data: any) => {
    return this.state.cols.map((item: TableHeaderSchema, index) => {
      if(index > 0 && item.show === true){
        if(item.name === "status"){
          if(this.props.statusButtons !== undefined) {
            return this.props.statusButtons(data);
          }
          if(data[item.name] === 1){
            return <td key={index}><span className={'badge badge-primary'}>{Lang.active}</span></td>
          }
          if(data[item.name] === 2){
            return <td key={index}><span className={'badge badge-warning'}>{Lang.inactive}</span></td>
          }
          return <td key={index}>{data[item.name]}</td>
        }

        if(item.name === "action") {
          return this.props.rowActions(data);
        }
        return <td key={index}>{data[item.name]}</td>
      }
    })
  }

  /**
   *@method createRecords
   * @desc create records for table automatic and dynamic according to condition
   * @param data
   * @param {number} index
   * @returns {any}
   */
  createRecords = (data: any, index: number) => {
    if (this.props.showRecords === undefined){
      return (
        <tr key={data.id.toString()}>
          {this.props.showCheckbox === true && <td>{this.checkbox(data.id)}</td> }
          {this.createdTd('id', index)}
          {this.Records(data)}
        </tr>
      );
    } else {
      return this.props.showRecords(data, index);
    }
  }

  render() {
    return(
      <div className="kt-portlet__body" style={{paddingTop:0}}>
        {
          this.state && this.state.records && this.state.records.length > 0 && ((!this.state.is_load) || (this.props.loader !== undefined)) &&

          <div className={'kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded'}>
            <TableActions {...{...this.props, ...this.state}}
                          onFieldUpdate={this.addRemoveFields}
                          limits={<Limit
                            totalPage={Math.ceil(this.state.PaginationMeta.total/this.state.PaginationMeta.per_page)}
                            totalRecods={this.state.PaginationMeta.total}
                            onPageChange={this.changePage}
                            onLimitChange={this.changeLimit}
                            from={this.state.PaginationMeta.from}
                            to={this.state.PaginationMeta.to}
                            perPage={this.state.PaginationMeta.per_page}
                            forcePage={this.state.PaginationMeta.current_page}
                          />}


            />

            <Table columns={this.state.cols}
                   onShowContent={ this.createRecords }
                   data={this.state.records}
                   checkAll={this.checkedAll}
                   start={this.state.PaginationMeta.from}
                   onSort={this.sort}
                   showCheckbox={this.props.showCheckbox !== undefined ? this.props.showCheckbox : true }
            />


            <Pagination
              totalPage={Math.ceil(this.state.PaginationMeta.total/this.state.PaginationMeta.per_page)}
              totalRecods={this.state.PaginationMeta.total}
              onPageChange={this.changePage}
              onLimitChange={this.changeLimit}
              from={this.state.PaginationMeta.from}
              to={this.state.PaginationMeta.to}
              perPage={this.state.PaginationMeta.per_page}
              forcePage={this.state.PaginationMeta.current_page}
            />
          </div>
        }
        {
          (this.state && this.state.is_load === true && (this.props.loader === undefined || this.state.first) ) &&
          <div className={'kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded'}>
            <div className="alert alert-solid-brand" role="alert">
              <div className="alert-icon"><i className="flaticon-warning"/></div>
              <div className="alert-text">{Lang.please_wait}</div>
            </div>
          </div>
        }
        {
          this.state && this.state.records &&  this.state.records.length <= 0 && this.state.is_load !== true &&
          <div className="alert alert-outline-warning fade show" role="alert">
            <div className="alert-icon"><i className="flaticon-warning"/></div>
            <div className="alert-text">No records found!</div>
          </div>
        }
      </div>
    );
  }
}
