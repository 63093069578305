import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {AgeGroupModel, CompetenceModel, ConceptInfoModel, ConceptModel, QuestionnaireStatement} from "../../../model";
import {IState} from "../Data";
import {PillarService, QuestionnaireService} from "../../../services";
import {ConceptInfoForm} from "./ConceptInfoForm";

export const PillarForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as ConceptModel);
  const [competence, setCompetence] = React.useState([] as Array<CompetenceModel>);
  const [ageGroup, setAgeGroup] = React.useState([] as Array<AgeGroupModel>);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0]  : target.value;
    const name = target.name;
    setForm({...form, [name]: value})
  }

  React.useEffect(() => {
    setForm(props.concept)
  },[props.concept])

  React.useEffect(() => {
     async function init() {
       const competenceList: Array<CompetenceModel> = await PillarService.getCompetenceList();
       const ageGroupList: Array<AgeGroupModel> = await QuestionnaireService.getAgeGroups();
       setCompetence(competenceList);
       setAgeGroup(ageGroupList);
     }
     init()
  },[])


    const handleEditorChange = (message : any, editor : any) => {
      setForm({...form, summary: message})
    }

    const FormInputs = [
      {
        "name" : "competence_id",
        "value" : _.get(form, "competence_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose Competence",
        "label": "Pillar Competence",
        "options": competence
      },
      {
        "name" : "age_group_id",
        "value" : _.get(form, "age_group_id", ""),
        "onChange": handleChange,
        "type": "select",
        "placeholder": "Choose age group",
        "label": "Age group",
        "options": ageGroup
      },
      {
        "name" : "name",
        "value" : _.get(form, "name", ""),
        "onChange": handleChange,
        "required": true,
        "placeholder": "Enter concept name",
        "label": "Concept name",
      },
      {
        "name" : "file",
        //"value" : _.get(form, "icon", ""),
        "onChange": handleChange,
        "type": "file",
        "label": "Icon",
        "file_path":  _.get(form, "icon", "")
      },
      {
        "name" : "summary",
        "value" : _.get(form, "summary", ""),
        "onEditorChange": handleEditorChange,
        "required": true,
        "placeholder": "Enter summary",
        "type": "editor",
        "label": "Summary",
        "parent_class": "col-md-12 col-lg-12"
      },
    ];

    function isDisable() {
      return FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id){
      PillarService.updateConcept(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/pillars/concepts`)
      }).catch(() => {});
    } else {
      PillarService.storeConcept(form).then((message) => {
        showNotification("success", message);
        history.push(`/pillars/concepts`)
      }).catch(() => {});
    }
  }


  function updateConceptInfo(data:Array<ConceptInfoModel>) {
    setForm({
      ...form,
      concept_info: data
    })
  }

  function addMore() {
    const concept_info = _.get(form, "concept_info", []);
    concept_info.push({} as ConceptInfoModel)  ;
    updateConceptInfo(concept_info);
  }

  function remove(num: number) {
    const {concept_info} = form;
    const updatedStatements: Array<ConceptInfoModel> = concept_info.filter((item, index) => index !== num)
    updateConceptInfo(updatedStatements);
  }
  const handleStatements = (index: number) => (event: React.ChangeEvent<HTMLElement>) => {
    const item = form.concept_info;
    const target: any = event.target;
    const {name, value} = target;
    item[index][name] = value;
    updateConceptInfo(item)
  }

  const handleEditor =  index => (value) => {
    const item = form.concept_info;
    item[index]["description"] = value;
    console.log(item);
    updateConceptInfo(item)
  }
  if(_.isUndefined(form.concept_info)){
    addMore();
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
              <ConceptInfoForm onChange={handleStatements} editorChange={handleEditor} form={form} remove={remove}/>
            </div>
            <div className={"col-md-12 col-lg-12"}>
              <div style={{textAlign: "end"}}>
                <button type={"button"} onClick={addMore} style={{marginTop:25}} className={"btn btn-primary btn-icon"}><i className={"fa fa-plus"}/></button>
              </div>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
      )
}