import * as React from 'react';
import API from "../../common/AxoisClient";
import { Editor } from '@tinymce/tinymce-react';
import {showNotification, LoaderType, Breadcrumb, SwitchLoader,SubmitButton} from "../../common";
import Lang from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import {EditBreadcrumb} from "./Data";
import {EmailTemplateValidations} from "./Validations";
declare const window: any;

export interface ETemplateState {
    id : string;
    name : string;
    type : string;
    subject : string;
    content : string;
    keyword : string;
    typeList : Array<any>;
    keywordList : any;
    keywordArr : Array<any>;
}

export class EditEmailTemplates extends React.Component<RouteComponentProps,ETemplateState> {
    state: ETemplateState;
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            id:'',
            name:'',
            type:'',
            subject:'',
            content:'',
            keyword:'',
            typeList: [],
            keywordList: {},
            keywordArr: [],
        }
        EmailTemplateValidations();
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.emailTempUpdate = this.emailTempUpdate.bind(this);

        let tinymce: any;
    }

    handleEditorChange(content : any, editor : any) {
        if(content !== ''){
            $( '.tox-tinymce' ).css( 'border-color', '#ccc' );
            $("#errorEditor").html(" ");
            this.setState({ content });
        } else {
            $( '.tox-tinymce' ).css( 'border-color', '#fd397a' );
            $("#errorEditor").html("This field is required.");
            this.setState({ content });
        }
    }


    onChange = (event : React.ChangeEvent<any>) => {
        const target = event.target;
        const {name, value} = target;
        this.setState({[name]: value } as any);
    }


    UNSAFE_componentWillMount(){
        var url = window.location.pathname;
        var id = url.substring(url.lastIndexOf('/') + 1);
        API.get("emailtemplates/get-email-data",{params:{'id':id}}).then(response => {

            /*console.log('_______________A_________________');
            console.log(this.state.typeList);

            console.log(response);
            console.log('_______________B_________________');*/

            let keywordListData:Array<any> = []
            if(response.data.type && this.state.typeList[response.data.type]){
                keywordListData = this.state.typeList[response.data.type];
            }

            this.setState((prevState:any) => ({
                id:id,
                name:response.data.name,
                type:response.data.type,
                subject:response.data.subject,
                content:response.data.content,
                keywordArr: response.data.keywords.split(","),
                keywordList: keywordListData
            }) );
        })
    }

    emailTempUpdate(event : any) {
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if($("#create_emailTemplate").valid() || this.state.content === '') {
            if(this.state.content === ''){
                $( '.tox-tinymce' ).css( 'border-color', '#fd397a' );
                $("#errorEditor").html("This field is required.");
                return false;
            }

            API.post("emailtemplates/email-temp-update", this.state).then(response => {
                showNotification("success", Lang.email_temp_updated);
                this.props.history.push(`/settings/email-templates`);
            })
        }
    }

    render() {
        return (
            <div>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <Breadcrumb data={EditBreadcrumb}/>
                    <form className="kt-form" onSubmit={this.emailTempUpdate} id={'create_emailTemplate'}>
                        <div className="col-md-12">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">
                                            {Lang.edit_email_template}
                                        </h3>
                                    </div>
                                </div>
                                <div className="kt-portlet__body">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control" id="name" name="name"
                                               value={this.state.name} onChange={this.onChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>Subject</label>
                                        <input type="text" className="form-control"
                                               id="subject" name="subject" value={this.state.subject} onChange={this.onChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>Content</label>
                                        <Editor apiKey="aowvzx5np520a0j8uimtcb8yax0qv017e88t0p5j5xmq8t27" id='insertText' init={{
                                            menubar:false, branding: false, forced_root_block : false, plugins: ['code', 'table'],
                                            toolbar: "undo redo | alignleft aligncenter alignright | bold italic | link |  fontsizeselect  | bullist | numlist | table|code",
                                            height:600
                                        }} value={this.state.content} onEditorChange={this.handleEditorChange} />
                                        <span id='errorEditor' style={{color:'#fd397a'}}/>
                                    </div>
                                </div>
                            </div>

                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                 <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        )
    }
}
