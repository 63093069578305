import React from "react";
import _ from "lodash";
import {Form} from "../Data";
import {CommonSubmitButton, isEmpty, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import API from "../../../common/AxoisClient";
import { useHistory } from "react-router-dom";
import {AgeGroupModel, UserModel} from "../../../model";
import { QuestionnaireService} from "../../../services";

export const UserForm = ({update, ...props}) => {
    let history = useHistory();
    const [form, setForm] = React.useState({} as Form);
    const [ageGroup, setAgeGroup] = React.useState([] as Array<AgeGroupModel>);

    const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setForm({...form, [name]: value})
    }

    React.useEffect(() => {
        async function init() {
            const ageGroupList: Array<AgeGroupModel> = await QuestionnaireService.getAgeGroups();
            setAgeGroup(ageGroupList);
        }
        init()
    },[])


    React.useEffect(() => {
        setForm(_.get(props, "form", {} as UserModel))
    }, [props.form])

    const FormInputs = [
        {
            "name" : "role_id",
            "value" : _.get(form, "role_id", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Select role",
            "type": "select",
            "label": "Role",
            "options": _.get(props, "roles", [])
        },
        {
            "name" : "age_group_id",
            "value" : _.get(form, "age_group_id", ""),
            "onChange": handleChange,
            "placeholder": "Select age group",
            "type": "select",
            "required": true,
            "label": "Age group",
            "options": ageGroup,
            "show": form.role_id == 4
        },
        {
            "name" : "first_name",
            "value" : _.get(form, "first_name", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter first name",
            "type": "text",
            "label": "First Name"
        },
        {
            "name" : "last_name",
            "value" : _.get(form, "last_name", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter last name",
            "type": "text",
            "label": "Last Name"
        },
        {
            "name" : "email",
            "value" : _.get(form, "email", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter email",
            "type": "email",
            "label": "Email"
        },
        {
            "name" : "username",
            "value" : _.get(form, "username", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter username",
            "type": "text",
            "label": "Username"
        },
        {
            "name" : "password",
            "value" : _.get(form, "password", ""),
            "onChange": handleChange,
            "placeholder": "Enter password",
            "type": "password",
            "label": "Password"
        },
        {
            "name" : "mobile_no",
            "value" : _.get(form, "mobile_no", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter mobile no",
            "type": "text",
            "label": "Mobile No."
        },
        {
            "name" : "dob",
            "value" : _.get(form, "dob", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter date of birth",
            "type": "date",
            "label": "Date of birth"
        }
    ];

    function isDisable() {
      return FormInputs.reduce((acc, val) => acc ? acc :
        (isEmpty(val.value) && val.required && val.show === undefined) || (isEmpty(val.value) && val.required && val.show), false);
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
      if(update){
          API.post(`users/users/${props.id}`, {...form, '_method': 'PUT'}).then((res) => {
              showNotification("success", res.data.message);
              history.push(`/users/users`)
          }).catch(() => {})
      } else {
          API.post('users/users', form).then((res) => {
              showNotification("success", res.data.message);
              history.push(`/users/users`)
          }).catch(() => {})
      }
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
      )
}