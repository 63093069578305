import React, {useEffect, useRef} from 'react';
import { Options} from "select2";
import _ from "lodash";
declare let window:any;

export interface Select2Props  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  data: Options
}

export function  Select2Wrapper (props: Select2Props) {
  const ele = useRef(null);
  useEffect(() => {
    let $el = ele.current;
    if($el !== null){
      let $els = $($el);
      $els.empty();
      $els.select2( _.get(props, "data", {}))
        .off( 'select2:select')
        .on("select2:select", (event: any) => {
        if(props.onChange !== undefined )
          props.onChange(event)
        });

        if(props.value !== undefined){
          let val: any = props.value;
          $els.val(val).trigger('change.select2');
        }
    }
  }, [props.data.data, props.value]);

  const {data, onChange, value, ...rest} = props;
    return(
      <select ref={ele} {...rest}>
        {props.children}
      </select>
    )
}