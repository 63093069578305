import React, {useRef, useEffect} from 'react';
import _ from 'lodash';
import {DatePickerOptions} from "../Common";


export interface DatePickerProps  extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: DatepickerOptions
}


export function DatePickerWrapper (props: DatePickerProps) {

  const datetime_picker = useRef(null);

  useEffect(() => {

  }, []);
  const $el = datetime_picker.current;
  if($el !== null ){
    $el.readOnly = true;
    $($el).datepicker({...DatePickerOptions, ...props.options}).on('changeDate', function(ev: any) {
      props.onChange(ev)
    });
  }

  return(
    <input type={'text'} ref={datetime_picker} {... _.omit(props, ['options', 'ref', "type"])} />
  )
}