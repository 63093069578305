import {PageModel} from "../../model";

export const urls = {
  home: "/settings/",
  list: "/settings/pages",
}

export const ListBreadcrumb = [
  {
    label: "Settings",
    url: urls.home
  },
  {
    label: "Pages",
    url: "#"
  }
];


export const EditBreadcrumb = [
  {
    label: "Settings",
    url: urls.home
  },
  {
    label: "Pages",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export interface IState {
  id: number,
  page: PageModel
}