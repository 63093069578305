import {ClubModel, ConceptModel} from "../../model";

export const urls = {
  home: "/clubs/",
  list: "/clubs/clubs",
}

export const ListBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export interface IState {
  id: number,
  club?: ClubModel,
}