import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import API from "../../../common/AxoisClient";
import { useHistory } from "react-router-dom";
import {PageModel} from "../../../model";
import {IState} from "../Data";

export const PageForm = (props: IState) => {
    let history = useHistory();
    const [form, setForm] = React.useState({} as PageModel);

    const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setForm({...form, [name]: value})
    }

    React.useEffect(() => {
      setForm(props.page)
    },[props.page])


    const handleEditorChange = (message : any, editor : any) => {
      setForm({...form, content: message})
    }

    const FormInputs = [
        {
            "name" : "name",
            "value" : _.get(form, "name", ""),
            "onChange": handleChange,
            "disabled": true,
            "placeholder": "Enter Page name",
            "label": "Page name",
        },
        {
            "name" : "title",
            "value" : _.get(form, "title", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter title",
            "label": "Page title"
        },
        {
            "name" : "slug",
            "value" : _.get(form, "slug", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter slug",
            "label": "Page slug"
        },
        {
            "name" : "content",
            "value" : _.get(form, "content", ""),
            "onEditorChange": handleEditorChange,
            "required": true,
            "placeholder": "Enter page content",
            "type": "editor",
            "label": "Page content",
            "parent_class": "col-md-12 col-lg-12"
        },
    ];

    function isDisable() {
      return FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.put(`settings/pages/${props.id}`, form).then((res) => {
      showNotification("success", res.data.message);
      history.push(`/settings/pages`)
    }).catch(() => {})
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
      )
}