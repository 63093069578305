export const Icons = {
  users: "flaticon-users",
  download: "la la-download",
  excel: "la la-file-excel-o",
  file: "la la-file-text-o",
  add: "la la-plus",
  edit: "la la-edit",
  delete: "flaticon-delete",
  view: "flaticon2-information",
  company_info: "flaticon2-list-3",
  company_time: 'flaticon-time-1',
  users2: 'flaticon-users-1',
  password: 'flaticon-lock',
  claim: 'flaticon-interface-4',
  email: 'flaticon2-new-email',
  mobile_no: 'flaticon2-phone',
  address: 'flaticon2-maps',
  order: 'flaticon2-shopping-cart-1',
  female: 'fa fa-female',
  male: 'fa fa-male',
  bank: 'la la-bank',
  club: 'la la-bank',
  setting: 'flaticon2-gear',
  permission: 'flaticon-lock',
  language: 'fa fa-language',
  user: 'flaticon2-user',
  password2: 'flaticon2-lock',
  order2: 'flaticon2-shopping-cart',
  notification: 'flaticon2-notification',
  tick_mark: 'flaticon2-check-mark',
  user_tick: 'fa fa-user-check',
  user_untick: 'fa fa-user-times',
  contract: 'flaticon2-contract',
  notes: 'flaticon-notes',
  cancel: 'flaticon-cancel',
  comment: 'flaticon-comment',
  price: 'fa fa-dollar-sign',
  restore: 'fa fa-trash-restore',
  complete: 'flaticon2-check-mark',
  sms: 'fa fa-sms',
  dollor: 'fa fa-dollar-sign',
  leaves: 'fa fa-user-cog',
  cross: 'fa fa-times',
  question: 'flaticon-questions-circular-button',
  activity_log : 'fa fa-history',
  refresh : 'flaticon-refresh',
  bell : 'fa fa-bell',
  enquire : 'fa fa-question-circle',
  import:'fa fa-file-import',
}
