import React, {useState, useEffect} from 'react';
import {Icons, LoaderType, RequestWaiting, SetWaitingArea, SwitchLoader} from "../common";
import {DashboardService} from "../services";
import {CustomerInfo} from "../users/customers/CustomerInfo";
import Lang from "../common/lang/Lang";
import {UserModel} from "../model";

export function RecentUsers({type, title}){

  const [customers, setCustomers] = useState([]);
  const [customerShow, setCustomerShow] = useState(false);
  const [user, setUser] = useState({} as UserModel);


  useEffect(() => {
    getRecentOrders();
  }, []);

  function getRecentOrders() {
    SwitchLoader(LoaderType.NONE)
    SetWaitingArea(type);
    DashboardService.getRecentUsers(type).then(res => {
      setCustomers(res.customers);
    })
  }

  function showOrderModel(info: UserModel) {
    setCustomerShow(true);
    setUser(info);
  }

  return (
    <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {title}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand" role="tablist">
            <li className="nav-item">
              <div className="nav-link">
                <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" onClick={getRecentOrders}>
                  <i className={Icons.refresh} title={Lang.refresh}  data-toggle="kt-tooltip" data-placement="top" data-original-title={Lang.refresh} />
                </button>
              </div>

            </li>
          </ul>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="tab-content">
          <div className="tab-pane active" id="kt_widget4_tab1_content">
            <div className="kt-widget4">
              <RequestWaiting item={customers} from={type}>
                {customers.length > 0 && customers.map((item: UserModel) => {
                  return <UserList item={item} showUserInfo={(info) => showOrderModel(info)} key={item.id}/>
                })}
              </RequestWaiting>
            </div>
          </div>
        </div>
      </div>

      {customerShow &&
        <CustomerInfo
          show={customerShow}
          onHide={() => setCustomerShow(false)}
          userinfo={user}
        />
      }

    </div>
  
  );
}


const UserList = (props:{item: UserModel, showUserInfo(info): void}) => {
  return (
    <div className="kt-widget4__item">
      {/*<div className="kt-widget4__pic kt-widget4__pic--pic">
        <img src={props.item.profile_image} alt=""/>
      </div>*/}
      <div className="kt-widget4__info">
        <a href="#" className="kt-widget4__username">
          {props.item.name}
        </a>
        <p className="kt-widget4__text">
          {props.item.email}
        </p>
      </div>
      <button className="btn btn-sm btn-label-brand btn-bold" onClick={() => props.showUserInfo(props.item)}>{Lang.user_info}</button>
    </div>
  )
}