import React from 'react';
import API from "../common/AxoisClient";
import {Auth} from "../Auth/Auth";

export class DashboardService {

  /**
   * get recent activity
   * @returns {Promise<T>}
   */
  public static async recentActivityLog() {
    const res = await API.get(`activity-log`);
    return res.data;
  }

  /**
   * get recent orders
   * @returns {Promise<T>}
   */
  public static async getRecentOrders() {
    const res = await API.get(`recent-orders`);
    return res.data;
  }

    /**
   * get recent orders
   * @returns {Promise<T>}
   */
  public static async getOrderCount(start,end) {
    const res = await API.post(`orders-count`,{startDate:start,endDate:end});
    return res.data;
  }

     /**
   * total translator
   * @returns {Promise<T>}
   */
  public static async getTranslatorCount(start,end) {
    const res = await API.post(`translator-count`,{startDate:start,endDate:end});
    return res.data;
  }

  /**
   * get recent register users
   * @returns {Promise<T>}
   */
  public static async getRecentUsers(type) {
    const res = await API.get(`recent-users`, {params: {type: type}});
    return res.data;
  }

  /**
   * get order graph data
   * @returns {Promise<T>}
   */
  public static async getOrderGraphData(start,end) {
    const res = await API.post(`order-graph`,{startDate:start,endDate:end});
    return res.data;
  }
  /**
   * get recent claims
   * @returns {Promise<T>}
   */
  public static async getClaimsData() {
    const res = await API.get(`recent-claims`);
    return res.data;
  }
 
}