import * as React from 'react';
import {Icons} from "../../common";
import Lang from "../../common/lang/Lang";
import {UserModel} from "../../model";
import {Link} from "react-router-dom";
import {UserService} from "../../services/UserService";

interface CustomerDSchemas {
    customer_id: number;
    active: string;
}

export class BasicCustomerDetail extends React.Component <CustomerDSchemas>{
    state: {
        user: UserModel,
        active: string
    };
    constructor(props: CustomerDSchemas) {
        super(props);
        this.state = {
            user:  {} as UserModel,
          active: props.active
        };
    }

    getData = async () => {
        const userInfo = await UserService.getUserInfo(this.props.customer_id);
        this.setState({
            user: userInfo
        });
    }

    componentDidMount() {
      this.getData();
    }

    render() {

        const fontsize = { fontSize: '15px'};
        const TabsItems = [
          {title: Lang.customer_info, to: `/users/users/info/${this.props.customer_id}`, active: 'info',fontIcon:Icons.user },
          {title: Lang.change_password, to: `/users/users/info/${this.props.customer_id}/change-password`, active: 'password',fontIcon:Icons.password2},
          {title: Lang.notification, to: `/users/users/info/${this.props.customer_id}/notification`, active: 'notification', fontIcon:Icons.notification },
          {title: Lang.activity_logs, to: `/users/users/info/${this.props.customer_id}/activitylog`, active: 'activitylog', fontIcon:Icons.users },
        ];


        return (
            <div className="kt-widget kt-widget--user-profile-1">

                <div className="kt-widget__head">
                    <div className="kt-widget__content">
                        <div className="kt-widget__section">
                            <a href="#" className="kt-widget__username">
                                {this.state.user.name}&nbsp;

                                { (this.state.user.status==1) ?
                                    <i className="flaticon2-correct kt-font-success"/>:<i className="flaticon2-close-cross" style={{color:'#299798'}}/>}
                            </a>
                            {/*<span className="kt-widget__subtitle">
                                { (this.state.user.gender=="male") ? <i className={Icons.male} style = {fontsize}/>: <i className={Icons.female} style = {fontsize}/> }  {this.state.user.gender}
                            </span>*/}
                        </div>
                    </div>
                </div>
                <div className="kt-widget__body">
                    <div className="kt-widget__content">
                        <div className="kt-widget__info">
                            <span className="kt-widget__label">{Lang.email}:</span><br/>
                            <span className="kt-widget__data">{this.state.user.email}</span>
                        </div>
                        <div className="kt-widget__info">
                            <span className="kt-widget__label">{Lang.telephone_number}:</span>
                            <span className="kt-widget__data">{this.state.user.mobile_no}</span>
                        </div>
                    </div>


                  <div className="kt-widget__items">
                    {TabsItems.map((item: any, index: number) => {
                     return (
                       <Link key={index}
                             className={this.state.active === item.active ? 'kt-widget__item kt-widget__item--active kt-widget__desc' : 'kt-widget__item'}
                             to={item.to} data-ktwizard-type="step"
                             onClick={() => {this.setState({active: item.active})}} >
                            <span className="kt-widget__section">
                                <span className="kt-widget__icon"/>
                                <span className="kt-widget__desc" ><i className={item.fontIcon}/> {item.title}</span>
                            </span>
                       </Link>
                     )

                    })}
                    
                  </div>
                </div>
            </div>
        );
    }
}