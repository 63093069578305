import React from 'react';
import {EditBreadcrumb} from "./Data";
import {Breadcrumb} from "../../common/page/Breadcrumb";
import {BasicCustomerDetail} from "./Side";
import {RouteComponentProps} from "react-router";
import {Route} from "react-router-dom";
import {CustomerEdit} from "./Edit";
import {CustomerChangePassword} from "./ChangePassword";
import _ from 'lodash';
import {Notification} from "../../common/page/Notification";
import {ActivityLog} from "./ActivityLog";
import {UserService} from "../../services/UserService";
import {UserModel} from "../../model";

export function CustomerTabs(props: RouteComponentProps) {

  const routes = {
    info: props.match.path,
    password: `${props.match.path}/change-password`,
    notification: `${props.match.path}/notification`,
    activitylog: `${props.match.path}/activitylog`,
  };

  const {id}: any = props.match.params;

  function activeRoute() {
    return _.findKey(routes, function(v) {
      v = _.replace(v, ':id', id);
      return v === props.location.pathname;
    })
  }

  return(
    <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
      <Breadcrumb data={EditBreadcrumb}/>
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
          <div className="kt-grid__item kt-app__toggle kt-app__aside" id="kt_user_profile_aside">
            <div className="kt-portlet ">
              <div className="kt-portlet__head  kt-portlet__head--noborder">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">
                  </h3>
                </div>
              </div>
              <div className="kt-portlet__body kt-portlet__body--fit-y">
                <BasicCustomerDetail  customer_id={id} active={activeRoute()} />
              </div>
            </div>
          </div>

          <Route exact path={routes.info} component={CustomerEdit} />
          <Route path={routes.password} component={CustomerChangePassword}  />
          <Route path={routes.notification} component={Notification}  />
          <Route path={routes.activitylog} component={ActivityLog}  />
        </div>
      </div>
    </div>
  )
}