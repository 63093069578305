import * as React from 'react';
import {AgeGroupModel, ConceptModel, PillarModel, QuestionnaireModel} from "../../model";
import {IState as CommonState} from "../../common/State";
import {actionButton, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {listingStatus} from "../../helpers/UserHelper";
import {QuestionnaireService} from "../../services";
import {get} from "lodash";

interface IState extends CommonState{
  pillars: Array<PillarModel>;
  age_groups: Array<AgeGroupModel>
   
}

export class Questionnaire extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();


  componentDidMount() {
    const ___that = this;
    async function  init() {
      const ageGroups = await QuestionnaireService.getAgeGroups();
      ___that.setState({
        age_groups: [{"label": "Select Age group", "value": ""}, ...ageGroups]
      })
    }
    init();
  }

  showContent = (data: QuestionnaireModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("age_group_id", get(data.age_group, "title", "-")),
      this.records.current.createdTd("title", data.title),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at",data.created_at),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: QuestionnaireModel) => {
    return (
      <EditLink scope={'questionnaire.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Questionnaire"} icon={Icons.question} >
            <ExportButton records={this.records} fileName={"Questionnaires"}/>
            &nbsp;
            <CreateLink to={`/quiz/questionnaire/create`} scope={'questionnaire.store'} />
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords} age={_.get(this.state, "age_groups", [])}  />}
            table={(
              <ShowRecords
                url={"/quiz/questionnaire"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "quiz/questionnaire/bulk-action", "module": 'questionnaire'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
