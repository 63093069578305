import React from 'react';
import {default as Lang} from "../../common/lang/Lang";
import {GeneratePermitLink, Icons, showNotification} from "../../common";
import Swal from "sweetalert2";
import API from "../../common/AxoisClient";
import {sendMail} from "../../services";

export function CustomerActions(props) {

  

  function approve(event: any,translator_id:number,status:number){
    Swal.fire({
      title: Lang.is_sure,text: Lang.sure_approve,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value){
        API.post("users/translator/approve", {translator_id:translator_id, status:status}).then(response => {
          status===1?showNotification("success", Lang.success_approve):showNotification("success", Lang.success_unapprove);
        });
      }
    })
  }

  return (
        <span style={{overflow: 'visible', position: 'relative', width: '80'}}>
          <div className="dropdown">
            <a data-toggle="dropdown" className="btn btn-sm btn-clean btn-icon btn-icon-md" aria-expanded="false">
              <i className="flaticon-more-1"/>
            </a>
            <div className="dropdown-menu dropdown-menu-right"  x-placement="bottom-end">
              <ul className="kt-nav">
               
              <li className="kt-nav__item">
                  <GeneratePermitLink className="kt-nav__link" scope={'users.resend-email'} to={'#'} onClick={() => sendMail(props.customer_data)}>
                      <i className={`${Icons.email}`} />&nbsp;&nbsp;<span className="kt-nav__link-text">Send confirmation mail</span>
                  </GeneratePermitLink>
                </li>
                  
              </ul>
            </div>
          </div>
        </span>

  )
}