import * as React from 'react';
import {AgeGroupModel, CompetenceModel, ConceptModel, PillarModel} from "../../model";
import {IState as CommonState} from "../../common/State";
import {actionButton, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {listingStatus} from "../../helpers/UserHelper";
import {PillarService, QuestionnaireService} from "../../services";

interface IState extends CommonState {
  pillars: Array<PillarModel>;
  competence: Array<CompetenceModel>;
  age_groups: Array<AgeGroupModel>;
   
}

export class Concepts extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  componentDidMount() {
    const ___that = this;
    async function  init() {
      const competenceList: Array<CompetenceModel> = await PillarService.getCompetenceList();
      const ageGroupList: Array<AgeGroupModel> = await QuestionnaireService.getAgeGroups();
      ___that.setState({
        competence: competenceList,
        age_groups: ageGroupList
      });
    }
    init();
  }

  showContent = (data: ConceptModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("competence_id", data.competence.name),
      this.records.current.createdTd("age_group_id", _.get(data.age_group, "title", "-")),
      this.records.current.createdTd("name", data.name),
      this.records.current.createdTd("icon", !_.isEmpty(data.icon) ?  <img src={data.icon} className={"img-responsive"} height={100} width={100}/> : "N/A"),
      this.records.current.createdTd("summary", data.summary.substring(0, 150)),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at",data.created_at),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: ConceptModel) => {
    return (
      <EditLink scope={'concept.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Concepts"} icon={Icons.bank} >
            <ExportButton records={this.records} fileName={"Concepts"}/>
            &nbsp;
            <CreateLink to={`/pillars/concepts/create`} scope={'concept.store'} />
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords}
                           competence={_.get(this.state, "competence", [])}
                           age_groups={_.get(this.state, "age_groups", [])}
            />}
            table={(
              <ShowRecords
                url={"/pillars/concepts"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "pillars/concepts/bulk-action", "module": 'concept'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
