import {PillarModel} from "../../model";

export const urls = {
  home: "/pillars/",
  list: "/pillars/pillars",
}

export const ListBreadcrumb = [
  {
    label: "Pillars",
    url: urls.home
  },
  {
    label: "Pillars",
    url: "#"
  }
];


export const EditBreadcrumb = [
  {
    label: "Pillars",
    url: urls.home
  },
  {
    label: "Pillars",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export interface IState {
  id: number,
  pillar: PillarModel
}