import React, {useState} from 'react';

import Lang from "../lang/Lang";
import {SubmitButtonService} from "../../services";


export const CommonSubmitButton = (props) => {
  return (
    <div className="kt-portlet__foot">
      <div className="kt-form__actions">
        <div className="row">
          <div className="col-lg-6">
          </div>
          <div className="col-lg-6 kt-align-right">
            <SubmitButton className="btn btn-primary" label={Lang.submit} {...props}/>
          </div>
        </div>
      </div>
    </div>
  )
};

interface SubmitButtonSchema extends React.HTMLAttributes<HTMLButtonElement> {
  label: string
}

export function SubmitButton(props: SubmitButtonSchema) {

  const [active, setActive] = useState(true);

  SubmitButtonService.getLoading().subscribe((message:any) => {
   
    setActive(message)
  });

  if(active === true){

    return (
      <button type={"submit"} {...props}>{props.label}</button>
    );
  } else {
    return (
      <button type={"button"} className={'btn btn-outline-dark btn-icon kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger'}/>
    );
  }
}