import API from "../common/AxoisClient";
import {state_to_form_data} from "../helpers/UserHelper";

export class ClubService {

  /**
   * get pillers list
   */
  static async getClubInfo(id: number){
    const res = await API.get(`clubs/clubs/${id}`);
    return res.data
  }

  static async coachDirectorsList () {
    const users = await API.get('clubs/clubs/coach-directors-list');
    return users.data;
  }


  static async playersList (q: string) {
    const users = await API.get('clubs/teams/players-list', {params: {q: q}});
    return users.data;
  }

  static async coachesList () {
    const users = await API.get('clubs/teams/coach-list');
    return users.data;
  }

  static async clubsList () {
    const users = await API.get('clubs/clubs/clubs-list');
    return users.data;
  }
  

  static async storeClub(form) {
    const store = await API.post("clubs/clubs", state_to_form_data(form));
    return  store.data;
  }

  static async updateClub(form, id) {
    const store = await API.post(`clubs/clubs/${id}`, state_to_form_data({...form, '_method': 'PUT'}));
    return  store.data;
  }


  static async storeTeam(form) {
    const store = await API.post("clubs/teams", form);
    return  store.data;
  }

  static async updateTeam(form, id) {
    const store = await API.post(`clubs/teams/${id}`, {...form, '_method': 'PUT'});
    return  store.data;
  }


  static async dataForTeam() {
    const store = await API.get("clubs/teams/create");
    return  store.data;
  }

  static async getTeamInfo(id: number){
    const res = await API.get(`clubs/teams/${id}`);
    return res.data
  }

}