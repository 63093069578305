import * as React from 'react';
import Localization, {default as Lang} from './lang/Lang';
import {ShowRecords} from "./ShowRecords";
import {Icons} from "./Icon";
import {Auth} from "../Auth/Auth";
import Swal from "sweetalert2";
import _ from 'lodash';

const createBrowserHistory = require("history").createBrowserHistory;
let moment = require("moment");
declare var jQuery: any;
declare var window: any;

export const CONST = {
  pageRender: 10,
  urls: {
    push_image: `${window.PUBLIC_URL}/admin/assets/media/icons/push_agreement.png`
  },
  status: [
    {value:"", label: "Select Status"},
    {value:1, label: Localization.active},
    {value:2, label: Localization.inactive}
  ],
  date_format: 'DD-M-YYYY',
  editorKey: "aowvzx5np520a0j8uimtcb8yax0qv017e88t0p5j5xmq8t27",
  // todo change editor path
  initialEditorOptions: {
    menubar:false, mode: "textareas", forced_root_block : false ,
    plugins: 'media,code, image',
    toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | media | image| code',
    images_upload_url: 'http://aim-pillar.local/api/admin/tiny-mce-file-upload',
  }
};


export const queryString =(params:any) => {
  // Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return $.param(params);
};


export const getQueryStringParams = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(key)) {
    return urlParams.get(key)
  }
  return false;
};

export const updatePageUrl = ( url: string, title?: string) => {
  if(url !== window.location.href){
    const history = createBrowserHistory();
    history.listen(() => {
      console.log("ooh, a navigation!");
    });
    history.push(url);
   // window.history.pushState("", title, url);
  }
};

export const getOldFormData = () => {
  let old_data: any = {};
  let form = getQueryStringParams("form");
  if(form) {
    old_data.form = JSON.parse(form);
  }
  let sort: any = getQueryStringParams('sort');
  if(sort){
    old_data.sort = sort;
  }
  let limit: any = getQueryStringParams('limit');
  if(limit){
    old_data.limit = limit;
  }
  let page: any = getQueryStringParams('page');
  if(page){
    old_data.page = page;
  }
  let type: any = getQueryStringParams('type');
  if(type){
    old_data.type = type;
  }
  return old_data;
}

export const showNotification = (type: string, message: string) => {
  $.notify(
      {message: message},
    {
      type: type,
      placement: {
              from: 'bottom',
              align: 'right'
            },
      newest_on_top: true,
      z_index: 99999999,
      });
};


export const showConfirmBox = (msg: string, callback: () => void) => {
  return Swal.fire({
    title: Lang.is_sure,
    text: msg,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ok'
  }).then((result) => {
    if (result.value) {
      return callback()
    }
  })
}

export function is_permit (scope: string|Array<string>){
  if(Auth.userInfo.role_id === 1 || scope === ''){
    return true;
  }
  if(_.isArray(scope)){
    return Auth.userInfo.scopes.some(item => scope.includes(item))
  } else {
    return Auth.userInfo.scopes.indexOf(scope) > -1;
  }

}

export function time_list () {
  let hours = [];
  for(let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format('h:mm'));
    hours.push(
      moment({
        hour,
        minute: 5
      }).format('h:mm')
    );
  }
  return hours;
}

export const TimePickerOptions = {
  minuteStep: 5,
  maxHours: 24,
  showMeridian: false,
  icons: {
    up: 'la la-angle-up',
    down: 'la la-angle-down'
  }
}

export const DatePickerOptions ={
  format: 'yyyy-mm-dd',
  autoclose: true,
  todayHighlight: true,
  //startDate: new Date(),
  templates : {
  leftArrow: '<i class="la la-angle-left"/>',
    rightArrow: '<i class="la la-angle-right"/>'
  }
}

export const DatePickerOptionsForCreateOrUpdate ={
  format: 'yyyy-mm-dd',
  autoclose: true,
  todayHighlight: true,
  //startDate: new Date(),
  templates : {
  leftArrow: '<i class="la la-angle-left"/>',
    rightArrow: '<i class="la la-angle-right"/>'
  }
}

export interface ExportButtonSchema  {
  records: React.RefObject<ShowRecords>,
  fileName:string
}

export const ExportButton = (props: ExportButtonSchema) => {

  return (
    <div  className="dropdown dropdown-inline">
      <div className={'exportButtonBeforeClick'}>
        <button type="button" className="btn btn-default btn-icon-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className={Icons.download}/> { Localization.export}
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <ul className="kt-nav">
            <li className="kt-nav__section kt-nav__section--first">
              <span className="kt-nav__section-text">Choose an option</span>
            </li>
            <li className="kt-nav__item">
              <a href={'/'}onClick={(e) => {e.preventDefault(); props.records.current.export('excel',props.fileName)} } className="kt-nav__link">
                <i className={`kt-nav__link-icon ${Icons.excel}`}/>
                <span className="kt-nav__link-text">{ Localization.excel}</span>
              </a>
            </li>
            <li className="kt-nav__item">
              <a href={'/'} onClick={(e) => {e.preventDefault(); props.records.current.export('csv',props.fileName)} }  className="kt-nav__link">
                <i className={`kt-nav__link-icon ${Icons.file}`}/>
                <span className="kt-nav__link-text">CSV</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={'exportButtonAfterClick hide-me'}>
        <button type="button" className="btn btn-default btn-icon-sm">
          <i className={Icons.download}/> Please wait...
        </button>
      </div>
    </div>
  )
}


export const NoRecords = () => {
  return (
    <div className="alert alert-outline-warning fade show" role="alert">
      <div className="alert-icon"><i className="flaticon-warning"/></div>
      <div className="alert-text">No records found!</div>
    </div>
  )
}

export function isEmpty(str) {
  return (!str || 0 === str.length);
}